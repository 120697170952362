var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "search-input",
          staticStyle: {
            "margin-bottom": "-20px",
            position: "relative",
            "z-index": "1",
            "margin-top": "5px",
          },
          style: { visibility: _vm.showDataSearch ? "visible" : "hidden" },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "input-common",
              staticStyle: { width: "140px" },
              attrs: { clearable: "", placeholder: "Chọn khối" },
              on: {
                change: function ($event) {
                  return _vm.changeGradeMethod()
                },
              },
              model: {
                value: _vm.dataSearch.idGrade,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "idGrade", $$v)
                },
                expression: "dataSearch.idGrade",
              },
            },
            _vm._l(_vm.gradeOfSchoolList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { value: item.id, label: item.gradeName },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "input-common",
              staticStyle: { width: "140px" },
              attrs: { filterable: "", clearable: "", placeholder: "Chọn lớp" },
              on: {
                change: function ($event) {
                  return _vm.searchByProperties()
                },
              },
              model: {
                value: _vm.dataSearch.idClass,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "idClass", $$v)
                },
                expression: "dataSearch.idClass",
              },
            },
            _vm._l(_vm.classList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { value: item.id, label: item.className },
              })
            }),
            1
          ),
          _c(
            "el-input",
            {
              staticStyle: { width: "280px" },
              attrs: { placeholder: "Nhập tên lớp", clearable: "" },
              on: {
                clear: function ($event) {
                  return _vm.searchByProperties()
                },
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchByProperties()
                },
              },
              model: {
                value: _vm.dataSearch.className,
                callback: function ($$v) {
                  _vm.$set(_vm.dataSearch, "className", $$v)
                },
                expression: "dataSearch.className",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.searchByProperties()
                  },
                },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClickTab },
              model: {
                value: _vm.activeTabName,
                callback: function ($$v) {
                  _vm.activeTabName = $$v
                },
                expression: "activeTabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Media setting", name: "mediaSetting" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingData,
                          expression: "loadingData",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "empty-text": _vm.textTableSetting,
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.mediaSettingList,
                        "header-cell-style": _vm.tableHeaderColor,
                        border: "",
                      },
                      on: {
                        "selection-change": _vm.handleSelectionChangeMedia,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "className",
                          label: "Tên lớp",
                          width: "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Danh sách áp dụng" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return _vm._l(
                                scope.row.mediaList,
                                function (item) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: item.id,
                                      staticStyle: { "margin-right": "8px" },
                                      attrs: { type: "", effect: "plain" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.mediaName) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }
                              )
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Tác vụ",
                          width: "130",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addMediaForClassMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Thêm Media\n              ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Media config", name: "mediaConfig" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingData,
                          expression: "loadingData",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "empty-text": _vm.textTableConfig,
                        "element-loading-text": _vm.$tableLoading,
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.mediaListConfig,
                        "header-cell-style": _vm.tableHeaderColor,
                        border: "",
                      },
                      on: {
                        "selection-change": _vm.handleSelectionChangeMedia,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "mediaName", label: "Tên media" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "linkMedia", label: "Media Link" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "mediaType",
                          label: "Loại",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "scopeType",
                          label: "Phạm vi",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Kích hoạt",
                          align: "center",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.activeMediaMethod(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.mediaActive,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "mediaActive", $$v)
                                    },
                                    expression: "scope.row.mediaActive",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "note", label: "Ghi chú" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Tác vụ",
                          width: "140",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateMediaMethod(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("button.update")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteOneMediaMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("button.delete")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { float: "right", margin: "15px 0" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button-bottom",
                          attrs: { type: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.addMediaMethod()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-circle-check" }),
                          _vm._v("\n            Thêm media\n          "),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "Media config Home app",
                    name: "mediaConfigHomeApp",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingData,
                          expression: "loadingData",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "empty-text": _vm.textTableConfig,
                        "element-loading-text": _vm.$tableLoading,
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background": "rgba(255,255,255, 0)",
                        "highlight-current-row": "",
                        data: _vm.mediaListForHomeApp,
                        "header-cell-style": _vm.tableHeaderColor,
                        border: "",
                      },
                      on: {
                        "selection-change": _vm.handleSelectionChangeMedia,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "STT",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "mediaName", label: "Tên media" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "linkMedia", label: "Media Link" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "mediaType",
                          label: "Loại",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "scopeType",
                          label: "Phạm vi",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Kích hoạt",
                          align: "center",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.activeMediaMethod(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.mediaActive,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "mediaActive", $$v)
                                    },
                                    expression: "scope.row.mediaActive",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "note", label: "Ghi chú" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Tác vụ",
                          width: "140",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateMediaForHomeAppMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("button.update")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteOneMediaMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("button.delete")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { float: "right", margin: "15px 0" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button-bottom",
                          attrs: { type: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.addMediaForHomeAppMethod()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-circle-check" }),
                          _vm._v(
                            "\n            Thêm media màn Home\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("MediaUpdateDialog", {
        ref: "MediaUpdateDialog",
        attrs: { dialogVisible: _vm.showUpdateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseUpdateMethod()
          },
        },
      }),
      _c("MediaUpdateForHomeAppDialog", {
        ref: "MediaUpdateForHomeAppDialog",
        attrs: { dialogVisible: _vm.showUpdateForHomeAppDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseUpdateForHomeAppMethod()
          },
        },
      }),
      _c("MediaCreateDialog", {
        attrs: { dialogVisible: _vm.showCreateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateMethod()
          },
        },
      }),
      _c("MediaForHomeAppCreateDialog", {
        attrs: { dialogVisibleForHome: _vm.showCreateMediaForHomeDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseCreateMethod()
          },
        },
      }),
      _c("AddMediaForClassDialog", {
        ref: "AddMediaForClassDialog",
        attrs: { dialogVisible: _vm.showAddMediaDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseAddMediaMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }